<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="12">
            <b-row>

              <b-col lg="4">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.aru_kode" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.aru_kode" 
                    :rules="toValidate(mrValidation.aru_kode)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group>
                  <label>Unit Tujuan<span class="text-danger mr5">*</span></label>
                    <b-form-input type="text" class="form-control" v-model="row.aru_unit_tujuan" placeholder="e.g Poli Anak" :formatter="$parent.normalText" />
                    <VValidate 
                        name="Unit Tujuan" 
                        v-model="row.aru_unit_tujuan" 
                        :rules="toValidate(mrValidation.aru_unit_tujuan)"
                    />
                </b-form-group>
              </b-col>

              

              <b-col lg="4">
                <b-form-group>
                  <label>Warna Unit<span class="text-danger mr5">*</span></label>
                  <b-form-input type="color" class="form-control" v-model="row.aru_warna_unit" placeholder="e.g 0001"/>
                  <VValidate 
                    name="Warna Unit" 
                    v-model="row.aru_warna_unit" 
                    :rules="toValidate(mrValidation.aru_warna_unit)"
                  />
                </b-form-group>
              </b-col>

              
              <div class="col-md-9">
                  <div class="form-group">
                      <label for="audioUnitTujuan">Atur Audio<small class="txt_mandatory">*</small></label>
                      <div class="card border">
                      
                      <template v-if="(row.aru_audio||[]).length">
                          <div class="card-body">
                              <!-- If Empty -->
                              <!-- <span>Belum ada audio ditambahkan</span> -->
                              <div v-for="(v,k) in (row.aru_audio||[])" :key="k" class="btn-group btn-group-sm mr-1 mb-1" >
                                <a href="javascript:;" class="btn btn-outline-info btn-sm">
                                    <i class="mr-1 icon-volume-medium"></i>
                                    {{getConfigDynamic(mAudio,v)}}
                                </a>
                                <a href="javascript:;" @click="(row.aru_audio||[]).splice(k,1)" class="btn alpha-info border-info text-info btn-sm btn-icon" data-popup="tooltip" title="Hapus Audio">
                                    <i class="icon-x"></i>
                                </a>
                              </div>
                          </div>
                      </template>
                      <template v-else>
                          <span class="p-2"> Tidak ada data audio yang diinput </span>
                      </template>
                      
                      <div class="card-footer text-right" @click="openModalAudio = true">
                          <a href="javascript:;" class="btn btn-info btn-sm btn-labeled btn-labeled-left" data-toggle="modal" >
                          <b><i class="icon-plus2"></i></b>
                          Tambah Audio
                          </a>
                      </div>
                      </div>
                  </div>
              </div>
              
              <b-col lg="3">
                <b-form-group>
                  <label>Kategori<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Pilih Kategori" v-model="row.aru_category" :options="Config.mr.mUnitCategory" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  
                  <VValidate 
                    name="Unit" 
                    v-model="row.aru_category" 
                    :rules="{required : 1}"
                  />
                </b-form-group>

              </b-col>

            

              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>

    <b-modal v-model="openModalAudio" :title="'Pilih Audio'" size="md" ok-only ok-title="Tutup">
        <div class="modal-body p-0">
        <table class="table table-sm table-bordered table-hover">
            <thead>
            <tr>
                <th width="20">#</th>
                <th>Nama</th>
                <th width="80">Preview</th>
            </tr>
            </thead>
            <tbody>
            <b-form-checkbox-group
                class="display-c"
                id="checkbox-group-2 display-c"
                v-model="row.aru_audio"
                name="flavour-2"
            >
                <tr v-for="(v,k) in (mAudio||[])" :key="k">
                    <td>
                        <b-form-checkbox :value="v.value"></b-form-checkbox>
                    </td>
                    <td>{{v.text}}</td>
                    <td>
                        <audio controls>
                            <source :src="$parent.uploader(v.file)" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </td>
                </tr>
            </b-form-checkbox-group>
            </tbody>
        </table>
        </div>
        <div class="modal-footer p-0 d-block bg-white">
        <div class="form-group mb-0 mt-2">
            <label for="">Audio dipilih:</label>
            <div>
                <div class="btn-group btn-group-sm mr-1 mb-1" v-for="(v,k) in (row.aru_audio||[])" :key="k">
                    <a href="javascript:;" class="btn btn-outline-info btn-sm">
                    <i class="mr-1 icon-volume-medium"></i>
                        {{getConfigDynamic(mAudio,v)}}
                    </a>
                </div>
                <span v-if="!(row.aru_audio||[]).length"> - </span>
            </div>
        </div>
        </div>
    </b-modal>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mPoli: Array,
    mAudio: Array,
  },
  data(){
    return{
        openModalAudio : false
    }
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
        let text = ''
        if (value) {
            let index = master.findIndex(x => x.value == value)
            if (index !== -1) {
            text = master[index]['text']
            }
        }
        return text
    },
  },
}
</script>