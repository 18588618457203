<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status" :options="Config.mr.StatusOptions" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            
            <template #cell(aru_warna_unit)="data">
                <div :style="`background-color:${data.value}`" class="d-inline-block w-25 p-2"></div>
            </template>
            
            <template #cell(aru_audio)="data">
                <audio controls v-for="(v,k) in (data.value||[])" :key="k" class="elAud d-none" :id="data.item.aru_id+'|'+k">
                    <source :src="uploader(getConfigDynamicFile(mAudio,v))" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>

                <span v-if="!(data.value||[]).length"> - </span>
                <template v-else>
                    <button type="button" v-if="!data.item.isPlay" @click="playAudio(data.item.aru_id,0,(data.value||[]).length,data.index)" class="btn btn-icon alpha-purple text-purple-800 border-purple btn-sm" data-popup="tooltip" title="" data-original-title="Dengarkan Audio"><i class="icon-volume-medium"></i></button>
                    
                    <button type="button" v-else @click="pauseAudio(data.index)" class="btn btn-icon alpha-purple text-purple-800 border-purple btn-sm" data-popup="tooltip" title="" data-original-title="Dengarkan Audio"><i class="icon-pause2"></i></button>
                </template>
            </template>

            <template #cell(aru_file)="data">
                <audio controls>
                    <source :src="uploader(data.item.aru_file)" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
            </template>
            <template
              #cell(aru_is_active) = "data"
            >
              <b-badge
                v-if="data.value=='Y'"
                variant="success"
              >Aktif</b-badge>
              <b-badge
                v-else
                variant="danger"
              >Nonaktif</b-badge>
            </template>
            <template
              #cell(action)="data"
            >
              <div class="list-icons">
                <router-link class="list-icons-item"
                  v-if="moduleRole('edit')"
                  :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                  data-toggle="tooltip" data-placement="top" title="View"><i
                    class="icon-pencil5"></i></router-link>
                
                  <a href="javascript:;" class="list-icons-item"
                    v-if="moduleRole('delete')"
                    @click="doDelete(data.index, data.item)"
                    data-toggle="tooltip" data-placement="top" title="Delete"><i
                      class="icon-bin"></i></a>
                  <a href="javascript:;" class="list-icons-item"
                    v-if="moduleRole('changeStatus')"
                    @click="doChangeStatus(data.index, data.item)"
                    data-toggle="tooltip" data-placement="top" title="Change Status"><i
                      class="icon-cog6"></i></a>
              </div>
              <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'aru_id',
      statusKey:'aru_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'aru_kode',
          label: 'Kode',
        },
        {
          key: 'aru_unit_tujuan',
          label: 'Nama Unit',
        },
        {
          key: 'aru_warna_unit',
          label: 'Warna Unit',
        },
        {
          key: 'aru_audio',
          label: 'Audio',
        },
        {
          key: 'aru_is_active',
          label: 'Status',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
      mPoli: [],
      mAudio: [],
      keyPlay: null,
      idPlay: null
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{},{
        mPoli: this.mPoli,
        mAudio: this.mAudio
      })
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    playAudio(id,key,len,keyU){
        let elAud = document.querySelectorAll(".elAud")
        for(let i = 0; i < (elAud||[]).length; i++){
            let elem = elAud[i]
            elem.pause()
            elem.currentTime = 0
        }

        for(let i = 0; i < (this.data.data||[]).length; i++){    
            this.data.data[i]['isPlay'] = false
        }

        let el = document.getElementById(id+"|"+key)
        el.play()
        let self = this
        self.idPlay = id
        self.keyPlay = key
        this.data.data[keyU]['isPlay'] = true
        el.onended = function() {
            key = key + 1
            if(key < len){
                self.playAudio(id,(key),len,keyU)
                self.keyPlay = key
            }
        }
    },
    pauseAudio(keyU){
        let el = document.getElementById(this.idPlay+"|"+this.keyPlay)
        el.pause()
        this.data.data[keyU]['isPlay'] = false
    },
    doFill(){
      this.doFilter()
    },
    getConfigDynamicFile(master, value) {
        let text = ''
        if (value) {
            let index = master.findIndex(x => x.value == value)
            if (index !== -1) {
            text = master[index]['file']
            }
        }
        return text
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>